











import Vue from 'vue';

export default Vue.extend({
  name: 'BusySpinner',
  props: {
    label: { type: String, required: false, default: 'Loading...' },
    radius: { type: String, required: false, default: '4em' }, // 8em ~ 1" diameter
    offset: { type: String, required: false, default: '0px 0px 0px 0px' }, // margin-top, margin-right, margin-bottom, margin-left
    color: { type: String, required: false, default: 'success' }, // 'primary' blue, 'secondary' grey, 'danger' red, 'warning' yellow, 'success' green, 'info' aqua, 'light', 'dark'
    opacity: { type: String, required: false, default: '1.0' },
    position: { type: String, required: false, default: 'absolute' },
    isBusy: { type: Boolean, required: false, default: false },
  },
});
