import Vue, { Component } from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const QuickApply  = () => import(/* webpackChunkName: "jobapply" */ './views/Jobs/QuickApply.vue');
const JobEditor = () => import(/* webpackChunkName: "createjob" */ './views/Jobs/EditJob.vue');

export default new Router({
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
      if(to.hash) {
          return { 
            selector: to.hash,
            behavior: 'smooth' 
          };
      } else if(savedPosition) {
          return savedPosition;
      } else {
          return { x: 0, y: 0 };
      }
  },
  routes: [
    {
      path: '/', redirect: { name: 'Login' }
    },
    {
      path: '/about',
      name: 'FAQ',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/FAQ.vue')
    },
    {
      path: '/about/terms',
      name: 'TNC',
      component: () => import(/* webpackChunkName: "about" */ './components/account/TNC.vue')
    },
    {
      path: '/about/privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "about" */ './components/account/Privacy.vue')
    },
    {
      path: '/support',
      name: 'Support',
      component: () => import(/* webpackChunkName: "support" */ './views/Support.vue')
    },
    {
      path: '/boards/:pid',
      name: 'NavBoard',
      component: () => import(/* webpackChunkName: "navboard" */ './views/Boards/NavBoard.vue')
    },
    // profiles
    {
      path: '/profile/create',
      name: 'CreateProfile',
      component: () => import(/* webpackChunkName: "createprofile" */ './views/Orgs/CreateProfile.vue')
    },
    {
      path: '/profile/:pid/edit',
      name: 'EditProfile',
      component: () => import(/* webpackChunkName: "createprofile" */ './views/Orgs/EditProfile.vue')
    },
    {
      path: '/profile/:cid/create-child',
      name: 'CreateChildProfile',
      component: () => import(/* webpackChunkName: "createprofile" */ './views/Orgs/CreateChildProfile.vue')
    },
    {
      path: '/profile/add-admin',
      name: 'AddAdmin',
      component: () => import(/* webpackChunkName: "createprofile" */ './views/Orgs/AddAdminBackdrop.vue')
    },
    {
      path: '/profile/:pid/manage',
      name: 'ManageProfile',
      component: () => import(/* webpackChunkName: "viewprofile" */ './views/Orgs/ManageProfile.vue')
    },
    {
      path: '/profile/:pid',
      name: 'CompanyCareerPage',
      component: () => import(/* webpackChunkName: "viewprofile" */ './views/Orgs/CompanyCareerPage.vue')
    },
    // jobs
    {
      name: 'ActiveJobs',
      path: '/jobs/active',
      component: () => import(/* webpackChunkName: "viewprofile" */ './views/Jobs/ActiveJobs.vue')
    },
    {
      name: 'CreateJob',
      path: '/jobs/create',
      component: JobEditor,
    },
    {
      name: 'EditJob',
      path: '/jobs/:id/edit',
      component: JobEditor
    },
    {
      path: '/jobs/:id/manage',
      name: 'ManageJobListing',
      component: () => import(/* webpackChunkName: "managejob" */ './views/Jobs/ManageJobListing.vue')
    },
    {
      path: '/jobs/:id',
      name: 'JobListing',
      component: () => import(/* webpackChunkName: "joblisting" */ './views/Jobs/JobListing.vue')
    },
    {
      path: '/jobs/qualifiers/:id',
      name: 'QualificationQuestions',
      component: () => import(/* webpackChunkName: "createjob" */ './views/Jobs/QualQuestionConfig.vue')
    },
    {
      path: '/jobs/:id/apply',
      name: 'JobQuickApply',
      component: QuickApply
    },
    // accounts
    {
      path: '/account/register',
      name: 'Register',
      component: () => import(/* webpackChunkName: "wb2.0" */ './views/Account/Registration.vue')
    },
    {
      path: '/account/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "account" */ './views/Account/Login.vue')
    },
    {
      path: '/account/password-reset',
      name: 'RequestReset',
      component: () => import(/* webpackChunkName: "account" */ './views/Account/RequestReset.vue')
    },
    {
      path: '/account/password-update',
      name: 'PasswordReset',
      component: () => import(/* webpackChunkName: "account" */ './views/Account/ResetPassword.vue')
    },
    {
      path: '/account/profile',
      name: 'AccountProfile',
      component: () => import(/* webpackChunkName: "account" */ './views/Account/ManageUserAccount.vue')
    },
    {
      path: '/account/onboarding',
      name: 'Onboarding',
      component: () => import(/* webpackChunkName: "account" */ './views/Account/Onboarding.vue')
    },
    {
      path: '/relay',
      name: 'Redirect',
      component: () => import(/* webpackChunkName: "account" */ './views/Relay.vue')
    }
  ]
})
