import { Store } from 'vuex';
import { UserState, UserStore } from '@/store/UserStore';
import { MetaDataState, MetaDataStore } from '@/store/MetaData';

export type RootState = {
    user: Store<UserState>,
    metaData: Store<MetaDataState>
};

export const store = {
    modules: {
        user: UserStore,
        metaData: MetaDataStore
    }
};
