export interface MetaDataState {
  isLoading: Boolean;
}

export const MetaDataStore = {
  state: {
    isLoading: false,
  } as MetaDataState,
  getters: {
    isLoading(state: MetaDataState): Boolean {
      return state.isLoading;
    },
  },
  mutations: {
    setIsLoading(state: MetaDataState, value: Boolean) {
      state.isLoading = value;

      if (value) {
        setTimeout(() => {
          state.isLoading = false;
        }, 5000);
      }
    },
  },
};
