export enum ProfilePermissions {
    None = 0,
    View = 1,
    Edit = 2,
    ToggleLimit = 4,
    InviteTeammate = 8,
    InviteClient = 16,
    ManageUsers = 32,
    ManagePayments = 64,
    Suspend = 128
}

export enum JobPermissions {
    None = 0,
    View = 1,
    Edit = 2,
    Create = 4,
    Share = 8,
    ConfigureQuestions = 16,
    PublishToCareers = 32,
    Advertise = 64,
    Deactivate = 128
}

export enum CandidatePermissions {
    None = 0,
    View = 1,
    ViewResume = 2,
    ViewNotes = 4,
    Share = 8,
    Notate = 16,
    Rate = 32,
    Workflow = 64
}

export enum SystemPermissions {
    None = 0,
    Viewer = 1,
    Administrator = 2
}
