import { NullableString } from '@/models/Types';
import UserSession, { EmptySession } from '@/models/UserSession';
import { SystemPermissions } from '@/models/Permissions';
import { EffectivePrivileges } from '@/models/Privileges';
import Role from '@/models/Role';

export interface Filters {
  //name: string,
  category: string;
}

export interface UserState {
  userSession: UserSession;
  newroles: Role[];
  allroles: Role[];
  filters: Filters;
  fileUploadCountData: Array<string>;
}

export const UserStore = {
  state: {
    userSession: JSON.parse(
      sessionStorage.getItem('userSession') || JSON.stringify(EmptySession)
    ),
    newroles: [],
    allroles: [],
    filters: { category: '' },
    fileUploadCountData: [],
  } as UserState,
  getters: {
    isLoggedIn(state: UserState): boolean {
        return Boolean(state.userSession.token)
    },
    isWerkberry(state: UserState): boolean {
      return state.userSession.currentPrivileges.sys != SystemPermissions.None;
    },
    privileges(state: UserState): EffectivePrivileges {
      return state.userSession.currentOrg ==
        state.userSession.currentPrivileges.id
        ? state.userSession.currentPrivileges.cur
        : state.userSession.currentPrivileges.dsc;
    },
    sysPrivileges(state: UserState): SystemPermissions {
      return state.userSession.currentPrivileges.sys;
    },
    homeProfile(state: UserState): NullableString {
      return state.userSession.primaryOrg;
    },
    currentProfile(state: UserState): NullableString {
      return state.userSession.currentOrg;
    },
    allRoles(state: UserState): Role[] {
      return state.allroles;
    },
    newRoles(state: UserState): Role[] {
      return state.newroles;
    },
    filters(state: UserState): Filters {
      return state.filters;
    },
    isApplicantUploading: (state: UserState) => (id: string) => {
      if (!id) {
        return false;
      }

      return state.fileUploadCountData.includes(id) || false;
    },
  },
  mutations: {
    setUserSession(state: UserState, profile: UserSession) {
      state.userSession = profile;
      sessionStorage.setItem('userSession', JSON.stringify(profile));
    },
    setRoles(state: UserState, roles: Role[]) {
      if (roles.length == 2) {
        state.newroles = roles;
      } else {
        state.allroles = roles;
      }
    },
    setCategoryFilter(state: UserState, filter: string) {
      state.filters.category = filter;
    },
    setUploaderForApplicantId(state: UserState, id: string) {
      if (!state.fileUploadCountData.includes(id)) {
        state.fileUploadCountData.push(id);
      }
    },
    removeUploaderForApplicantId(state: UserState, id: string) {
      state.fileUploadCountData = state.fileUploadCountData.filter(
        (item) => item !== id
      );
    },
  },
};
