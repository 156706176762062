





















































































import Vue from 'vue';
import api from '@/api';
import { UUID } from '@/models/Types';
import { EmptySession } from '@/models/UserSession';
import { isIframeIntegration } from '@/common/iframe';

interface Data {
  toggleClient: boolean;
  userProfileImage: string | null;
}

export default Vue.extend({
  name: 'MenuBar',
  data(): Data {
    return {
      toggleClient: false,
      userProfileImage: null, // this can be string or null
    };
  },
  methods: {
    goHome() {
      const url = window.location.href.split('#');
      const pid: UUID = this.$store.getters.homeProfile;

      if (url && Array.isArray(url) && url.length > 1 && url[1].includes(`/boards/${pid}`)) {
        return;
      }
      this.$router.push({ path: pid === null ? '/' : `/boards/${pid}` });
    },
    goToSupport() {
      this.$router.push({ name: 'Support' });
    },
    goToFAQ() {
      this.$router.push({ name: 'FAQ' });
    },
    goToProfile() {
      this.$router.push({ name: 'AccountProfile' });
    },
    logout() {
      sessionStorage.removeItem('token');
      this.$store.commit('setUserSession', EmptySession);
      this.$router.replace({ name: 'Login' });
    },
    isInIframe() {
      return isIframeIntegration();
    },
  },
  computed: {
    environment(): string {
      return api.startsWith('https://werkberry.') ? '' : 'STAGING';
    },
    isUnknown() {
      return true;
    },
    isLoggedIn() {
        return this.$store.getters.isLoggedIn;
    }
  }
});
