import { UUID } from './Types';
import { ProfilePermissions, JobPermissions, CandidatePermissions, SystemPermissions } from './Permissions';
import { Privileges } from './Privileges';

export default interface UserSession {
    token: string;
    primaryOrg: UUID | null;
    currentOrg: UUID | null;
    currentPrivileges: Privileges;
}

export const EmptySession: UserSession = {
    token: '',
    primaryOrg: null,
    currentOrg: null,
    currentPrivileges: {
        id: '',
        cur: { p: ProfilePermissions.None, j: JobPermissions.None, c: CandidatePermissions.None },
        dsc: { p: ProfilePermissions.None, j: JobPermissions.None, c: CandidatePermissions.None },
        sys: SystemPermissions.None
    }
}
