



















import Vue from 'vue';

interface Data {
  external: boolean;
}

export default Vue.extend({
  name: 'IFrameFooter',
});
