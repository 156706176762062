















import '@/assets/css/werkberry.css';
import '@/assets/css/updated.css';
import Vue from 'vue';
import MenuBar from './components/MenuBar.vue';
import IFrameFooter from './components/IFrameFooter.vue';

import { isIframeIntegration } from '@/common/iframe';

import BusySpinner from '@/components/modals/BusySpinner.vue';
interface Data {
  external: boolean;
  isIframeIntegration: any;
}

export default Vue.extend({
  name: 'App',
  components: {
    MenuBar,
    IFrameFooter,
    BusySpinner,
  },
  data(): Data {
    return {
      external: false,
      isIframeIntegration,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    hideMenu() {
      this.external = true;
    },
    restoreMenu() {
      this.external = false;
    },
  },
  mounted() {
    if (isIframeIntegration()) {
      window.document.body.classList.add('pt-5');
      return true;
    }
    window.addEventListener('werkberry:show-error-toast', () => {
      this.$bvToast.show('error-toast');
    });
  },
});
