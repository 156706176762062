// import bootstrap 4 globally
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Vue from 'vue';
import VueResource from 'vue-resource';
import router from './router';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from './App.vue';
import { setInteractionMode } from 'vee-validate';
import VueGtag from 'vue-gtag';
import store from './store';
import * as Sentry from "@sentry/vue";

setInteractionMode('aggressive'); // default validation mode

Sentry.init({
    Vue,
    dsn: "https://25b23510219348b8bc0f023d40479a9c@o4505150334631937.ingest.sentry.io/4505150340988928",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: window.location.href.includes('app.werkberry.com') ? "production" : "dev",
    // Performance Monitoring
    tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production! cca 0.2
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
  

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// google gtag
Vue.use(
  VueGtag,
  {
    config: { id: 'G-9Z7D88VJGL', params: { send_page_view: false } },
    // appName: 'WB App',
    // pageTrackerScreenviewEnabled: true
  },
  router
);

Vue.http.interceptors.push((request: any, next: any) => {
  // These are defaults that can be overridden at the call site.
  request.headers.set('Content-Type', 'application/json');
  request.headers.set('Accept', 'application/json');
  const token = sessionStorage.token;
  // Not all requests need a bearer token
  // However, passing a bearer token to a request that doesn't
  // need it is harmless.
  if (typeof token === 'string')
    request.headers.set('Authorization', `Bearer ${token}`);

  next(function (response: any) {
    // 4xx - client side errors
    // 5xx - serve side errors

    // if response is error show toast
    if (response && response.status >= 400 && response.url) {
      let showError = true;

      // these routes are handled
      const handledResponseUrls = [
        'payments/rightad',
        'account/reset',
        'invite/validate/reset',
        'account/create',
        'account/authenticate',
        'payments/subscriptions',
      ];
      handledResponseUrls.map((url) => {
        if (response.url.toLowerCase().includes(url.toLowerCase())) {
          showError = false;
        }
      });

      if (showError) {
        try {
            Sentry.captureException(response);
          } catch (err) {
            console.log('Sending error to sentry failed...');
          }
        window.dispatchEvent(new CustomEvent('werkberry:show-error-toast'));
      }
    }
  });
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
